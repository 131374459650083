<script setup lang="ts">
import IconBoughtOffer from '@/assets/icon_check.png?url'
import IconMultiOffer from '@/assets/iconMultiOffer.png?url'
import { clickRouteToOffer } from '@/components/shared/OfferDialog/OfferDialogCompose'
import { AutoPlay, Pagination } from '@egjs/flicking-plugins'
import Flicking from '@egjs/vue3-flicking'
import '@egjs/vue3-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/flicking-plugins/dist/pagination.css'

const props = defineProps<Props>()

const { isDesktop } = tools

interface Props {
  offers: { [key in Games.GameKeyType]?: Array<Games.Offer> }
}

const plugins = [new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true }), new Pagination({ type: 'bullet' })]

const { list, gameLoggedOrder, sortByIsBought } = gamesUtils
const gamesKeysWithOffers = computed(() => {
  return gameLoggedOrder.value.filter((gameKey) => {
    return !!props.offers?.[gameKey]?.length
  })
})
const bannerOffers = computed(() => {
  const offers: Array<Games.Offer> = []
  for (const gameKey of gamesKeysWithOffers.value) {
    if (props.offers?.[gameKey]) {
      const gameOffer = sortByIsBought(props.offers?.[gameKey] ?? [])?.slice(0, 1)
      if (gameOffer) {
        offers.push(...gameOffer)
      }
    }
  }
  return offers
})
</script>

<template>
  <div class="max-w-lg big:max-w-5xl mx-auto px-2 a-fade min-h-[350px] big:min-h-[400px]">
    <div class="rounded-3xl overflow-hidden isolate">
      <Flicking
        :options="{ align: 'prev', circular: true }"
        :plugins="plugins"
        class="overflow-hidden"
      >
        <div
          v-for="(offer, index) in bannerOffers"
          :key="index"
          class="relative w-full overflow-hidden"
          :class="{ 'opacity-20': (offer?.expireSec || 0) < 0 }"
          :style="[`padding-top: ${isDesktop ? 38.29 : 113}%`]"
        >
          <router-link
            v-slot="{ href }"
            custom
            :to="{ name: 'game-product-page', params: { gameID: list[offer.gameKey].id, offerID: offer.id } }"
          >
            <a
              :href="href"
              :class="{ 'cursor-not-allowed': (!!offer?.moIsLocked && offer?.type !== 'goatIntegration') || (!!offer?.moGroupId && !!offer?.isBought) }"
              @click="clickRouteToOffer($event, offer)"
            >
              <div
                :key="`${offer.gameKey}_${offer.id}`"
                class="absolute inset-0 bg-cover bg-no-repeat bg-top bg-black"
                :class="{ grayscale: offer?.isBought }"
                :style="{
                  backgroundImage: `url('${
                    isDesktop ? offer.featured?.asset : offer.featured?.mobileAsset || offer.featured?.asset
                  }')`
                }"
              >
                <img
                  v-if="offer?.isMultiOffer"
                  alt="multi offer"
                  :src="IconMultiOffer"
                  class="absolute bottom-10 right-8 w-8"
                >
                <div
                  v-if="offer?.isBought"
                  class="absolute inset-0 bg-black/50"
                />
              </div>
              <div
                v-if="list[offer.gameKey]?.logo"
                class="absolute top-3 right-1"
              >
                <img
                  :src="list[offer.gameKey].logo"
                  alt="logo"
                  class="w-28"
                >
              </div>
              <div
                class="px-6 pt-28 big:pt-6 absolute left-0 bottom-0 pb-3 right-0 text-white bg-gradient-to-t from-black via-black/80 big:bg-none"
              >
                <div class="grid grid-cols-1 gap-2">
                  <div class="tracking-wide">
                    <div class="font-bold text-lg whitespace-pre-line leading-tight pb-1">
                      {{ offer?.name }}
                    </div>
                    <div
                      v-if="offer?.featured?.description?.length"
                      class="text-lg font-normal line-clamp-4 whitespace-pre-line leading-tight pt-1"
                    >
                      {{ offer?.featured?.description }}
                    </div>
                  </div>
                  <offer-items-block-component
                    :offer="offer"
                    medium
                    text-class="text-white"
                  />
                  <div class="text-left">
                    <div class="relative text-center inline-block pb-6 big:pb-5">
                      <buy-button-component
                        :offer="offer"
                        @click.stop
                      />
                      <div
                        v-if="offer?.purchaseLimit && offer.purchaseLimit < 1000"
                        class="text-xs text-white absolute bottom-1 big:bottom-0 right-0 left-0 text-center"
                      >
                        Limit: <b>{{ offer?.purchaseLimit }}</b> pcs
                      </div>
                    </div>
                  </div>
                </div>
                <countdown-component
                  class="text-xl translate-y-0 static"
                  :class="{ grayscale: offer?.isBought }"
                  :offer="offer"
                  @end="offer.expireSec = $event"
                />
              </div>
              <discount-ribbon-component
                :offer="offer"
                :class="{ grayscale: offer?.isBought }"
              />
              <div
                v-if="offer?.isBought"
                class="absolute w-full top-12 flex flex-col justify-center items-center"
              >
                <img
                  alt="already bought"
                  class="w-1/4 big:w-[15%]"
                  :src="IconBoughtOffer"
                >
                <div class="text-white text-base font-bold text-center pt-1.5">
                  Already bought
                </div>
              </div>
            </a>
          </router-link>
        </div>
        <template #viewport>
          <div class="flicking-pagination" />
        </template>
      </Flicking>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.flicking-viewport :deep(.flicking-pagination-bullet) {
  background-color: white;
  opacity: 0.6;
  &-active {
    opacity: 1;
  }
}
</style>
