<script setup lang="ts">
import LogInBanner from '@/components/shared/LogInBanner.vue'
import { offerDialog } from '@/components/shared/OfferDialog/OfferDialogCompose'
import { GameKeyEnum } from '@/types/enums'

const { logOut, triggerApi, checkCart, isLoggedGoat, isSomeConnectedToGoat, goadLoadAliveData } = gamesUtils
const bannersOffers = ref<{ [key in Games.GameKeyType]?: Array<Games.Offer> }>({})
const bestDealsOffers = ref<{ [key in Games.GameKeyType]?: Array<Games.Offer> }>({})
const topOffers = ref<{ [key in Games.GameKeyType]?: Array<Games.Offer> }>({})

let isMounted = false
const { debounce, isDesktop } = tools
const route = useRoute()
const { gaSections } = gaUtils

function loadGame(game: Games.GameItem) {
  if (game.api && game.active) {
    gamesUtils
      .apiGet(game.gameKey, 'offers/featured/')
      .then(({ data }) => {
        if (!isMounted) {
          return
        }
        gamesUtils.setLoyalty(game.gameKey, data?.storeUser)
        if (data.bannerOffers) {
          bannersOffers.value[game.gameKey] = gamesUtils.renderApiOffers(
            data.bannerOffers,
            game.gameKey,
            {
              gs_game: game.gameKey,
              gs_route: String(route.name),
              gs_section: gaSections.banner
            }
          )
            .offers
        }
        if (data.bestDeals) {
          bestDealsOffers.value[game.gameKey] = gamesUtils.renderApiOffers(
            data.bestDeals,
            game.gameKey,
            {
              gs_game: game.gameKey,
              gs_route: String(route.name),
              gs_section: gaSections.bestDeals
            }
          )
            .offers
        }
        if (data.topOffers) {
          topOffers.value[game.gameKey] = gamesUtils.renderApiOffers(
            data.topOffers,
            game.gameKey,
            {
              gs_game: game.gameKey,
              gs_route: String(route.name),
              gs_section: gaSections.offersList
            }
          )
            .offers
        }
        checkCart(
          [
            ...(bannersOffers.value[game.gameKey] ?? []),
            ...(bestDealsOffers.value[game.gameKey] ?? []),
            ...(topOffers.value[game.gameKey] ?? [])
          ],
          game.gameKey
        )
        if ((bestDealsOffers.value[game.gameKey]?.length || 0) < 2) {
          let usedIndex = 0
          if (topOffers.value[game.gameKey]?.length) {
            bestDealsOffers.value[game.gameKey] = bestDealsOffers.value[game.gameKey] ?? []
          }
          const bestDealsRefer = bestDealsOffers.value[game.gameKey]
          for (let i = 0; i < 2; i++) {
            const topOffer = topOffers.value[game.gameKey]?.[usedIndex]
            const bestDealOffer = bestDealsRefer?.[i]
            if (!bestDealOffer && topOffer && bestDealsRefer) {
              topOffer.gaContext = { ...(topOffer?.gaContext ?? {}), gs_section: gaSections.bestDeals }
              bestDealsRefer.push(topOffer)
              usedIndex++
            }
          }
        }
        if ((topOffers.value[game.gameKey]?.length || 0) < 4) {
          if (!topOffers.value[game.gameKey]) {
            topOffers.value[game.gameKey] = []
          }
          const topOffersRefer = topOffers.value[game.gameKey]
          if (topOffersRefer) {
            const bestDealsRefer = bestDealsOffers.value[game.gameKey]
            if (bestDealsRefer) {
              const mergedOffers = [...bestDealsRefer].map((offer) => {
                offer.gaContext = { ...(offer?.gaContext ?? {}), gs_section: gaSections.offersList }
                return offer
              })
              topOffersRefer.push(...mergedOffers)
            }
            if (topOffersRefer.length < 4) {
              const bannersOffersRefer = bannersOffers.value[game.gameKey]
              if (bannersOffersRefer) {
                const mergedOffers = [...bannersOffersRefer].map((offer) => {
                  offer.gaContext = { ...(offer?.gaContext ?? {}), gs_section: gaSections.offersList }
                  return offer
                })
                topOffersRefer.push(...mergedOffers)
              }
              if (topOffersRefer.length && topOffersRefer.length < 4) {
                const missing = 4 - topOffersRefer.length
                for (let i = 0; i < missing; i++) {
                  topOffersRefer.push(topOffersRefer[0])
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.status === 401) {
          logOut(game.gameKey, err)
        } else {
          gamesUtils.catchError(err)
        }
      })
  }
}

function loadAllGames() {
  if (!isMounted) {
    return
  }
  Object.entries(gamesUtils.list).forEach(([, game]) => {
    loadGame(game)
  })
}
const loadAllDebounced = debounce(() => {
  loadAllGames()
  goadLoadAliveData()
}, 500)
onMounted(() => {
  isMounted = true
  loadAllGames()
  goadLoadAliveData()
  watch(triggerApi.triggerRef, () => {
    if (!offerDialog.value.visible) {
      loadAllDebounced()
    }
  })
})
onUnmounted(() => {
  isMounted = false
})
const isSomeConnected = computed(() => isSomeConnectedToGoat(GameKeyEnum.goat))
</script>

<template>
  <div class="min-h-screen">
    <main-banner-component
      :offers="bannersOffers"
      class="mt-5"
    />
    <logos-cards-component class="mt-5" />
    <template v-if="isLoggedGoat">
      <goat-user-box
        v-if="!isDesktop || !isSomeConnected"
        :game-key="GameKeyEnum.goat"
        banner-mode
        class="mt-5 mb-5 p-2"
      />
    </template>
    <LogInBanner
      v-else
      class="mt-2"
    />
    <best-deals-component
      :deals="bestDealsOffers"
      class="mt-5"
    />
    <top-offers-component :top-offers="topOffers" />
    <faq-component
      class="my-10"
    />
  </div>
</template>

<style scoped></style>
